<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { TopRight, Odometer, Bell, User, SwitchButton, ArrowDown } from '@element-plus/icons-vue'

import { getProfile } from '../../services/profile.service'
import { useAuthStore } from '../../store/auth.store'

import NotificationItem from './components/notification-item.vue'
import SystemLogo from '@/components/system-logo/index.vue'

const router = useRouter()
const auth = useAuthStore()

const userIsStaff = computed(() => auth.isStaff)
const notifications = ref(null)

const logout = async () => {
  router.replace({
    name: 'logout'
  })
}

const goProfile = async () => {
  router.push({
    name: 'me'
  })
}

const goDashboard = async () => {
  router.replace({
    name: 'dashboard'
  })
}

const goWeb = async () => {
  location.href = 'https://www.pisciculturaglobal.com/'
}

const loadProfile = async () => {
  try {
    const { data: { data } } = await getProfile()

    auth.updateUser(data)
  } catch (error) {
    console.log('Error updating profile', error)
  }
}

onBeforeMount(loadProfile)
</script>
<template>
  <el-header id="AppHeader">
    <system-logo />

    <div class="headerMenu">
      <!-- <el-dropdown trigger="click" style="color: white">
        <span class="el-dropdown-link">
          <el-icon style="font-size: 32px">
            <bell style="width: 2em; height: 2em" />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
                v-for="notification in notifications"
                :key="notification.id">
                <notification-item
                  :notification="notification" />
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->

      <el-dropdown trigger="click" style="color: white" v-if="auth.isLogged">
        <span class="el-dropdown-link">
          <el-avatar :src="auth.user.avatar">
            <user style="width: 2em; height: 2em" />
          </el-avatar>
          <el-icon class="el-icon--right" color="white">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <li class="menu-greetings" v-if="auth.user">
              {{ $t('components.header.greeting') }} {{ auth.user.firstName }}
            </li>
            <!-- TODO: Incluir en el i18n -->
            <el-dropdown-item :icon="TopRight" @click="goWeb">
              {{ $t('components.header.website') }}
            </el-dropdown-item>
            <el-dropdown-item :icon="Odometer" @click="goDashboard" v-if="userIsStaff">
              {{ $t('components.header.dashboard') }}
            </el-dropdown-item>
            <el-dropdown-item :icon="User" @click="goProfile">
              {{ $t('components.header.profile') }}
            </el-dropdown-item>
            <el-dropdown-item :icon="SwitchButton" @click="logout">
              {{ $t('components.header.logout') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </el-header>
</template>
<style lang="sass">
#AppHeader
  background-color: #213d5b
  display: flex
  justify-content: space-between

  .headerMenu
    display: flex
    align-items: center
    justify-content: flex-end
    height: 100%
    padding-right: 20px

li.menu-greetings
  border-bottom: 1px solid var(--el-border-color-lighter)
  min-width: 10rem
  padding: 0.6rem 1rem
</style>
