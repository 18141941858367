import http from '../libs/http'

const getProfile = (userId) => {
  return http.get(
    `users/me`
  )
}

const saveProfile = user => {
  return http.put(
    `users/me/`,
    user
  )
}

export {
  getProfile,
  saveProfile
}
