import { nextTick } from 'vue'
import { ping } from '../services/auth/auth.service'
import { useAuthStore } from '../store/auth.store'

import defaultPlaces from './default.route'
import adminPlaces from './admin.route'
import coursePlaces from './course.route'
import careerPlaces from './career.route'
import publicPlaces from './public.route'
import userPlaces from './user.route'

const places = [
  ...defaultPlaces,
  ...adminPlaces,
  ...coursePlaces,
  ...careerPlaces,
  ...userPlaces,
  ...publicPlaces
]

const routes = places.map(route => {
	if (!route.hasOwnProperty('meta')) {
		route.meta = {}
	}

	route.meta.layout = (!route.meta.hasOwnProperty('layout')) ?
		'default' :
		route.meta.layout

  const r = {
    ...route
  }

  // const component_path = (r.meta.hasOwnProperty('reuse')) ? r.meta.reuse : route.name

  if (r.meta.hasOwnProperty('module')) {
    r.component = () => import(`@/pages/${route.meta.module}/${route.name}/index.vue`)
  } else {
    r.component = () => import(`@/pages/${route.name}/index.vue`)
  }

	return r
})

const checkAuth = async (to, from, next) => {
  // clear alert on route change
  // const alertStore = useAlertStore()
  // alertStore.clear()

  // Pages for wihout auth
  const noAuthPages = [
    'login',
    'register',
    'forgot',
    'logout'
  ]

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    'not-found',
    ...publicPlaces.map(p => p.name)
  ]

  const authStore = useAuthStore()
  const authRequired = !noAuthPages.includes(to.name)
  const isPublic = publicPages.includes(to.name)
  const role = (authStore.user) ? authStore.user.profile : null

  await nextTick()

  if (isPublic) {
    next()
  } else
  // If logged in and trying to access login page, redirect to home
  if (authStore.isLogged && !authRequired && to.name !== 'logout') {
  // if (authStore.isLogged && !authRequired) {
    console.log('Redirect to home')
    next('/')
  }
  // if not logged in and trying to access a restricted page, redirect to login
  else if (!authStore.isLogged && authRequired) {
    console.log('Redirect to login')
    next('/login')
  }
  // if logged in and trying to access a page for a specific role, redirect to home
  else if (
    authStore.isLogged &&
    authRequired &&
    to.meta.roles &&
    !to.meta.roles.includes(role)) {
    console.log('Forbidden, redirect to home')
    next('/')
  } else {
    next()
  }
}

export {
  routes as default,
	checkAuth
}
