const module = 'profile'

const places = [
  {
    name: 'me',
    path: '/profile',
    meta: {
      module
    }
  },
  {
    name: 'certificate',
    path: '/profile/certificate',
    meta: {
      module
    }
  // },
  // {
  //   name: 'profile',
  //   path: '/profile/',
  //   meta: {
  //     module
  //   }
  }
]

export default places
