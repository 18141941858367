import { defineStore } from 'pinia'
import { login, logout } from '../services/auth/auth.service'
import { register } from '../services/auth/register.service'

let user = null
const userTypeAdmin = [
  'admin',
  'staff',
  // 'professor'
]

const token = localStorage.getItem('token', null)
const isLogged = Boolean(token || false)

if (isLogged) {
  const storageUser = localStorage.getItem('user') || atob(token.split('.')[1])
  user = JSON.parse(storageUser)
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: user,
    isLogged: isLogged
  }),
  getters:{
    isStaff(state) {
      return state.user && userTypeAdmin.includes(state.user.profile)
    }
  },
  actions: {
    async login({ username, password }) {
      try {
        const response = await login(
          {
            username,
            password
          }
        )

        if (response.status === 200) {
          const { data: { data: { token, user } } } = response

          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          this.user = user
          this.isLogged = true

          return this.user
        } else {
          throw response
        }
      } catch (error) {
        console.error("Error al iniciar sesión:", error)

        this.user = null
        this.isLogged = false
        localStorage.removeItem('token')
        localStorage.removeItem('user')

        throw error
      }
    },
    async logout() {
      try {
        this.user = null
        this.isLogged = false
        localStorage.removeItem('user')
        localStorage.removeItem('token')

        await logout()
      } catch (error) {
        console.log(error)
      }
    },
    async register({ firstname, lastname, username, email, password }) {
      console.log(firstname, lastname, username, email, password)
      const { data: { data: { token, user } } } = await register(
        {
          firstname,
          lastname,
          username,
          email,
          password
        }
      )

      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))
      this.user = user
      this.isLogged = true

      return this.user
    },
    loadUser() {
      this.user = user
      this.isLogged = isLogged
    },
    updateUser(user) {
      this.user = user
      localStorage.setItem('user', JSON.stringify(user))
    }
  }
})


